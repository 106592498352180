/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SummaryQueryVariables = {
    userID: string;
};
export type SummaryQueryResponse = {
    readonly userSummary: {
        readonly totalReturn: number;
        readonly cagr: number;
        readonly sharpe: number;
        readonly maxDD: number;
        readonly betaGSPC: number;
        readonly betaUnderlying: number;
    };
};
export type SummaryQuery = {
    readonly response: SummaryQueryResponse;
    readonly variables: SummaryQueryVariables;
};



/*
query SummaryQuery(
  $userID: ID!
) {
  userSummary(userID: $userID) {
    totalReturn
    cagr
    sharpe
    maxDD
    betaGSPC
    betaUnderlying
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userID"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userID",
        "variableName": "userID"
      }
    ],
    "concreteType": "Summary",
    "kind": "LinkedField",
    "name": "userSummary",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalReturn",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cagr",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sharpe",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maxDD",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "betaGSPC",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "betaUnderlying",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SummaryQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SummaryQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a68571c468e81b1f74d1f172e01cc6fa",
    "id": null,
    "metadata": {},
    "name": "SummaryQuery",
    "operationKind": "query",
    "text": "query SummaryQuery(\n  $userID: ID!\n) {\n  userSummary(userID: $userID) {\n    totalReturn\n    cagr\n    sharpe\n    maxDD\n    betaGSPC\n    betaUnderlying\n  }\n}\n"
  }
};
})();
(node as any).hash = '8c51d5c6dda13de9d64d8763e2b854b7';
export default node;
