import React from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
} from '@coreui/react'

import { AccountForecastParameters } from './AccountForecastParameters'
import { AccountTradingParameters } from './AccountTradingParameters'
import { AccountRiskParameters } from './AccountRiskParameters'

export interface SettingsProps {
  accountID: string;
}

const Settings = ({ accountID }: SettingsProps) => {
  return (
    <>
      <CCard style={{ position: "relative" }}>
        <CCardHeader>
          <h1>Trading Parameters</h1>
        </CCardHeader>
        <CCardBody>
          <AccountTradingParameters accountID={accountID} />
        </CCardBody>
      </CCard>
      <CCard style={{ position: "relative" }}>
        <CCardHeader>
          <h1>Forecast Parameters</h1>
        </CCardHeader>
        <CCardBody>
          <AccountForecastParameters accountID={accountID} />
        </CCardBody>
      </CCard>
      <CCard style={{ position: "relative" }}>
        <CCardHeader>
          <h1>Risk Parameters</h1>
        </CCardHeader>
        <CCardBody>
          <AccountRiskParameters accountID={accountID} />
        </CCardBody>
      </CCard>
    </>
  )
}

export default Settings
