/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AccountRiskQueryVariables = {
    accountID: string;
    confidenceLevels: Array<number>;
};
export type AccountRiskQueryResponse = {
    readonly accountValueAtRisk: ReadonlyArray<{
        readonly accountID: string;
        readonly confidenceLevel: number;
        readonly risk: number;
    }>;
};
export type AccountRiskQuery = {
    readonly response: AccountRiskQueryResponse;
    readonly variables: AccountRiskQueryVariables;
};



/*
query AccountRiskQuery(
  $accountID: ID!
  $confidenceLevels: [Float!]!
) {
  accountValueAtRisk(accountID: $accountID, confidenceLevels: $confidenceLevels) {
    accountID
    confidenceLevel
    risk
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "confidenceLevels"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountID",
        "variableName": "accountID"
      },
      {
        "kind": "Variable",
        "name": "confidenceLevels",
        "variableName": "confidenceLevels"
      }
    ],
    "concreteType": "ValueAtRisk",
    "kind": "LinkedField",
    "name": "accountValueAtRisk",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accountID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "confidenceLevel",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "risk",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountRiskQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountRiskQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c1304d1a35f9d0d2fe337b0678674b58",
    "id": null,
    "metadata": {},
    "name": "AccountRiskQuery",
    "operationKind": "query",
    "text": "query AccountRiskQuery(\n  $accountID: ID!\n  $confidenceLevels: [Float!]!\n) {\n  accountValueAtRisk(accountID: $accountID, confidenceLevels: $confidenceLevels) {\n    accountID\n    confidenceLevel\n    risk\n  }\n}\n"
  }
};
})();
(node as any).hash = '7c530c471218bbcba242f21498bca528';
export default node;
