import React, { useContext } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import './scss/style.scss';
import { UserContext } from './contexts/user';
import { TheLayout } from './containers';
import { Home } from './views/pages/home'
import Login from './views/pages/login/Login';

function PrivateRoute({ component, authorized, ...rest }) {
  let c =
    authorized() === true ? (
      <Route component={component} {...rest} />
    ) : (
        <Redirect {...rest} to="/login" />
      );

  return c;
}

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const App = () => {
  const user = useContext(UserContext)

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          {/* @ts-ignore */}
          <Route exact path="/login" render={props => <Login {...props} />} />
          <Route exact path="/" render={props => <Home {...props} />} />
          <PrivateRoute
            authorized={() => {
              return user && user.id && user.id !== '';
            }}
            path="/dashboard"
            name="Dashboard"
            component={TheLayout}
          />
          <PrivateRoute
            authorized={() => {
              return user && user.id && user.id !== '';
            }}
            path="/accounts/:accountID"
            name="Accounts"
            component={TheLayout}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}

export default App;
