import React, { useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardFooter,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from '@coreui/react'

import { Chart } from './CumulativeReturns/Chart'
import { Positions } from './Positions'
import { Balances } from './Balances'
import { Summary } from './Summary'
import { Risk } from './Risk'

const Dashboard = () => {
  const [modal, setModal] = useState(true)

  return (
    <>
      <CModal show={modal} onClose={setModal}>
        <CModalHeader closeButton>
          <CModalTitle>Warning</CModalTitle>
        </CModalHeader>
        <CModalBody>The information provided on this page may not be accurate and should not be relied upon.</CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setModal(false)}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
      <CCard style={{ position: "relative" }}>
        <CCardBody>
          <Chart />
        </CCardBody>
      </CCard>
      <CCard style={{ position: "relative" }}>
        <CCardHeader>
          <h1>Summary</h1>
        </CCardHeader>
        <CCardBody>
          <Summary />
        </CCardBody>
        <CCardFooter>
          Updated daily at midnight UTC.
        </CCardFooter>
      </CCard>
      <CCard style={{ position: "relative" }}>
        <CCardHeader>
          <h1>Positions</h1>
        </CCardHeader>
        <CCardBody>
          <Positions />
        </CCardBody>
        <CCardFooter>
          Updated every 15 minutes.
        </CCardFooter>
      </CCard>
      <CCard style={{ position: "relative" }}>
        <CCardHeader>
          <h1>Balances</h1>
        </CCardHeader>
        <CCardBody>
          <Balances />
        </CCardBody>
        <CCardFooter>
          Updated every 15 minutes.
        </CCardFooter>
      </CCard>
      <CCard style={{ position: "relative" }}>
        <CCardHeader>
          <h1>Value At Risk</h1>
        </CCardHeader>
        <CCardBody>
          <Risk />
        </CCardBody>
        <CCardFooter>
          Updated every 15 minutes.
        </CCardFooter>
      </CCard>
    </>
  )
}

export default Dashboard
