import React from 'react'
import {
  Table,
} from 'reactstrap'
import {
  QueryRenderer
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useRelayEnvironment } from 'relay-hooks'

import { AccountRiskParametersQueryResponse } from './__generated__/AccountRiskParametersQuery.graphql'
import Loading from '../../../../components/Loading'

type AccountRiskParametersType = {
  readonly id: string;
  readonly accountID: string;
  readonly weeklyPriceMovementCap: number;
  readonly maxPercentLoss: number;
  readonly leverageCloseoutWithLossThreshold: number;
  readonly leverageCloseoutWithLossPriceErr: number;
  readonly tradingHaltedUntil: string;
  readonly hoursToHaltTrading?: number;
  readonly deleverageLeverageLimit: number;
  readonly deleverageLeverageLimitCloseoutRatio: number;
  readonly priceErr: number;
}

const AccountRiskParameter = (
  {
    accountRiskParameters: {
      id,
      accountID,
      weeklyPriceMovementCap,
      maxPercentLoss,
      leverageCloseoutWithLossThreshold,
      leverageCloseoutWithLossPriceErr,
      tradingHaltedUntil,
      hoursToHaltTrading,
      deleverageLeverageLimit,
      deleverageLeverageLimitCloseoutRatio,
      priceErr,
    }
  }:
    {
      accountRiskParameters: AccountRiskParametersType;
    }
) => {
  return (
    <tr>
      <td style={{ textAlign: 'center' }}>
        {id}
      </td>
      <td style={{ textAlign: 'center' }}>
        {accountID}
      </td>
      <td style={{ textAlign: 'center' }}>
        {(weeklyPriceMovementCap * 100).toFixed(4)}%
      </td>
      <td style={{ textAlign: 'center' }}>
        {(maxPercentLoss * 100).toFixed(4)}%
      </td>
      <td style={{ textAlign: 'center' }}>
        {leverageCloseoutWithLossThreshold}
      </td>
      <td style={{ textAlign: 'center' }}>
        {(leverageCloseoutWithLossPriceErr * 100).toFixed(4)}%
      </td>
      <td style={{ textAlign: 'center' }}>
        {tradingHaltedUntil}
      </td>
      <td style={{ textAlign: 'center' }}>
        {hoursToHaltTrading}
      </td>
      <td style={{ textAlign: 'center' }}>
        {deleverageLeverageLimit}
      </td>
      <td style={{ textAlign: 'center' }}>
        {(deleverageLeverageLimitCloseoutRatio * 100).toFixed(4)}%
      </td>
      <td style={{ textAlign: 'center' }}>
        {(priceErr * 100).toFixed(4)}%
      </td>
    </tr>
  )
}

export interface AccountRiskParametersQueryProps {
  accountID: string;
}

const AccountRiskParametersQuery = ({ accountID }: AccountRiskParametersQueryProps) => {
  const environment = useRelayEnvironment()

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query AccountRiskParametersQuery($accountID: ID!) {
          accountRiskParams(accountID: $accountID) {
            id
            accountID
            weeklyPriceMovementCap
            maxPercentLoss
            leverageCloseoutWithLossThreshold
            leverageCloseoutWithLossPriceErr
            tradingHaltedUntil
            hoursToHaltTrading
            deleverageLeverageLimit
            deleverageLeverageLimitCloseoutRatio
            priceErr
          }
        }
      `}
      variables={{
        accountID,
      }}
      render={({ error, props }) => {
        if (error) {
          console.error(error)
          return <>Error</>
        }
        console.log("props")
        console.log(props)

        let params: AccountRiskParametersQueryResponse["accountRiskParams"]
        if (props) {
          params = (props as AccountRiskParametersQueryResponse).accountRiskParams
        }

        return (
          <>
            {!props && !error ? <tr><td colSpan={11}><Loading isLoading={!props && !error} /></td></tr> : <></>}
            {params ? <AccountRiskParameter
              accountRiskParameters={params}
            />
              :
              <></>}
          </>
        )
      }}
    />
  )
}

export interface AccountRiskParametersProps {
  accountID: string;
}

export const AccountRiskParameters = ({ accountID }: AccountRiskParametersProps) => {
  return (
    <Table responsive className="center">
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>
            ID
          </th>
          <th style={{ textAlign: 'center' }}>
            Account ID
          </th>
          <th style={{ textAlign: 'center' }}>
            Weekly Price Movement Cap
          </th>
          <th style={{ textAlign: 'center' }}>
            Max Percent Loss
          </th>
          <th style={{ textAlign: 'center' }}>
            Leverage Closeout With Loss Threshold
          </th>
          <th style={{ textAlign: 'center' }}>
            Leverage Closeout With Loss Price Err
          </th>
          <th style={{ textAlign: 'center' }}>
            Trading Halted Until
          </th>
          <th style={{ textAlign: 'center' }}>
            Hours to Halt Trading
          </th>
          <th style={{ textAlign: 'center' }}>
            Deleverage Leverage Limit
          </th>
          <th style={{ textAlign: 'center' }}>
            Deleverage Leverage Limit Closeout Ratio
          </th>
          <th style={{ textAlign: 'center' }}>
            Price Error
          </th>
        </tr>
      </thead>
      <tbody>
        <AccountRiskParametersQuery accountID={accountID} />
      </tbody>
    </Table>
  )
}
