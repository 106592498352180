/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ChartUserCumulativeReturnsQueryVariables = {
    userID: string;
};
export type ChartUserCumulativeReturnsQueryResponse = {
    readonly userCumulativeReturns: {
        readonly data: ReadonlyArray<ReadonlyArray<number>>;
    };
};
export type ChartUserCumulativeReturnsQuery = {
    readonly response: ChartUserCumulativeReturnsQueryResponse;
    readonly variables: ChartUserCumulativeReturnsQueryVariables;
};



/*
query ChartUserCumulativeReturnsQuery(
  $userID: ID!
) {
  userCumulativeReturns(userID: $userID) {
    data
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userID"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userID",
        "variableName": "userID"
      }
    ],
    "concreteType": "CumulativeReturns",
    "kind": "LinkedField",
    "name": "userCumulativeReturns",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChartUserCumulativeReturnsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChartUserCumulativeReturnsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dcce4ce96b41eef820ac8eaa05a4d1ef",
    "id": null,
    "metadata": {},
    "name": "ChartUserCumulativeReturnsQuery",
    "operationKind": "query",
    "text": "query ChartUserCumulativeReturnsQuery(\n  $userID: ID!\n) {\n  userCumulativeReturns(userID: $userID) {\n    data\n  }\n}\n"
  }
};
})();
(node as any).hash = '5e2147ebacc3cff5da020eaa67225ba2';
export default node;
