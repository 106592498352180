import React, { useContext, useEffect } from 'react'
import {
  QueryRenderer
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useRelayEnvironment } from 'relay-hooks'
import { chart } from 'highcharts';

import { RiskQueryResponse } from './__generated__/RiskQuery.graphql'
import { UserContext } from '../../../contexts/user'
import Loading from '../../../components/Loading'

export const Risk = () => {
  const environment = useRelayEnvironment()
  const user = useContext(UserContext)

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query RiskQuery($userID: ID!, $confidenceLevels: [Float!]!) {
          userValueAtRisk(userID: $userID, confidenceLevels: $confidenceLevels) {
            accountID
            confidenceLevel
            risk
          }
        }
      `}
      variables={{
        userID: user?.id,
        confidenceLevels: [0.99],
      }}
      render={({ error, props }) => {
        if (error) {
          console.error(error)
          return <>Error</>
        }

        let data: RiskQueryResponse["userValueAtRisk"] = []
        if (props) {
          data = (props as RiskQueryResponse).userValueAtRisk
        }

        return (
          <>
            <Loading isLoading={!props && !error} />
            <div id="valueAtRiskChartWrapper">
              <RiskChart
                data={data}
                loading={!props}
              />
            </div>
          </>
        )
      }}
    />
  )
}


type userValueAtRisk = {
  readonly accountID: string;
  readonly confidenceLevel: number;
  readonly risk: number;
}
type RiskChartProps = {
  loading: boolean;
  data: readonly userValueAtRisk[];
}
const RiskChart = ({
  data,
}: RiskChartProps) => {
  useEffect(() => {
    const categories: string[] = data.map(datum => {
      return datum.accountID
    })

    const chartData: number[] = data.map(datum => {
      return datum.risk * 100
    })
    chart({
      chart: {
        type: 'column',
        renderTo: 'valueAtRiskChartWrapper',
      },

      title: {
        text: 'Account Values at Risk',
      },

      xAxis: {
        categories,
      },

      yAxis: {
        min: 0,
        title: {
          text: 'Percent Account Value at Risk'
        },
        labels: {
          format: '{value}%'
        }
      },
      series: [{
        name: 'P99',
        // @ts-ignore
        data: chartData,
      }]
    })
  }, [data])

  return (
    <></>
  )
}
