import React, { useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCardFooter,
} from '@coreui/react'
import {
  Col,
  Input,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

import { Forecasts } from './Forecasts/Forecasts'
import { Prices } from './Prices'
import { AccountRiskEvents } from './AccountRiskEvents'
import { LevAndPriceErr } from './LevAndPriceErr'

export interface HistoryProps {
  accountID: string;
}

const History = ({ accountID }: HistoryProps) => {
  const [forecastsLimit, setForecastsLimit] = useState(10)
  const [forecastsSkip, setForecastsSkip] = useState(0)
  const [pricesLimit, setPricesLimit] = useState(10)
  const [pricesSkip, setPricesSkip] = useState(0)
  const [riskEventsLimit, setRiskEventsLimit] = useState(10)
  const [riskEventsSkip, setRiskEventsSkip] = useState(0)

  return (
    <>
      <CCard style={{ position: "relative" }}>
        <CCardHeader>
          <h1>Leverage and Price Errors</h1>
        </CCardHeader>
        <CCardBody>
          <LevAndPriceErr
            accountID={accountID}
          />
        </CCardBody>
        <CCardFooter>
          Updated every 15 min
        </CCardFooter>
      </CCard>
      <CCard style={{ position: "relative" }}>
        <CCardHeader
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <h1>Prices</h1>
          <div>
            <Label
              for="limitSelect"
              sm={12}
            >
              Show
            </Label>
            <Col sm={12}>
              <Input
                id="limitSelect"
                name="select"
                type="select"
                value={pricesLimit}
                onChange={e => {
                  setPricesLimit(Number(e.target.value))
                }}
              >
                <option value={10}>
                  10
                </option>
                <option value={25}>
                  25
                </option>
                <option value={50}>
                  50
                </option>
              </Input>
            </Col>
          </div>
        </CCardHeader>
        <CCardBody>
          <Prices
            accountID={accountID}
            skip={pricesSkip}
            limit={pricesLimit}
          />
        </CCardBody>
        <CCardFooter
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          Updated every 15 min
          <Pagination>
            <PaginationItem
              disabled={pricesSkip === 0}
              onClick={() => {
                if (pricesSkip === 0) return;
                const tmpSkip = Math.max(
                  0,
                  pricesSkip - pricesLimit
                );
                setPricesSkip(tmpSkip)
              }}
            >
              <PaginationLink previous tag="button"></PaginationLink>
            </PaginationItem>
            <PaginationItem
              disabled={false}
              onClick={() => {
                const tmpSkip = pricesSkip + pricesLimit;
                setPricesSkip(tmpSkip);
              }}
            >
              <PaginationLink next tag="button"></PaginationLink>
            </PaginationItem>
          </Pagination>
        </CCardFooter>
      </CCard>
      <CCard style={{ position: "relative" }}>
        <CCardHeader
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <h1>Forecasts</h1>
          <div>
            <Label
              for="limitSelect"
              sm={12}
            >
              Show
            </Label>
            <Col sm={12}>
              <Input
                id="limitSelect"
                name="select"
                type="select"
                value={forecastsLimit}
                onChange={e => {
                  setForecastsLimit(Number(e.target.value))
                }}
              >
                <option value={10}>
                  10
                </option>
                <option value={25}>
                  25
                </option>
                <option value={50}>
                  50
                </option>
              </Input>
            </Col>
          </div>
        </CCardHeader>
        <CCardBody>
          <Forecasts
            accountID={accountID}
            skip={forecastsSkip}
            limit={forecastsLimit}
          />
        </CCardBody>
        <CCardFooter
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          Updated every 15 min
          <Pagination>
            <PaginationItem
              disabled={forecastsSkip === 0}
              onClick={() => {
                if (forecastsSkip === 0) return;
                const tmpSkip = Math.max(
                  0,
                  forecastsSkip - forecastsLimit
                );
                setForecastsSkip(tmpSkip)
              }}
            >
              <PaginationLink previous tag="button"></PaginationLink>
            </PaginationItem>
            <PaginationItem
              disabled={false}
              onClick={() => {
                const tmpSkip = forecastsSkip + forecastsLimit;
                setForecastsSkip(tmpSkip);
              }}
            >
              <PaginationLink next tag="button"></PaginationLink>
            </PaginationItem>
          </Pagination>
        </CCardFooter>
      </CCard>
      <CCard style={{ position: "relative" }}>
        <CCardHeader
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <h1>Risk Events</h1>
          <div>
            <Label
              for="riskEventsLimitSelect"
              sm={12}
            >
              Show
            </Label>
            <Col sm={12}>
              <Input
                id="riskEventsLimitSelect"
                name="select"
                type="select"
                value={riskEventsLimit}
                onChange={e => {
                  setRiskEventsLimit(Number(e.target.value))
                }}
              >
                <option value={10}>
                  10
                </option>
                <option value={25}>
                  25
                </option>
                <option value={50}>
                  50
                </option>
              </Input>
            </Col>
          </div>
        </CCardHeader>
        <CCardBody>
          <AccountRiskEvents
            accountID={accountID}
            skip={riskEventsSkip}
            limit={riskEventsLimit}
          />
        </CCardBody>
        <CCardFooter
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          Updated every 15 minutes.
          <Pagination>
            <PaginationItem
              disabled={riskEventsSkip === 0}
              onClick={() => {
                if (riskEventsSkip === 0) return;
                const tmpSkip = Math.max(
                  0,
                  riskEventsSkip - riskEventsLimit
                );
                setRiskEventsSkip(tmpSkip)
              }}
            >
              <PaginationLink previous tag="button"></PaginationLink>
            </PaginationItem>
            <PaginationItem
              disabled={false}
              onClick={() => {
                const tmpSkip = riskEventsSkip + riskEventsLimit;
                setRiskEventsSkip(tmpSkip);
              }}
            >
              <PaginationLink next tag="button"></PaginationLink>
            </PaginationItem>
          </Pagination>
        </CCardFooter>
      </CCard>
    </>
  )
}

export default History
