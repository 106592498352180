import React from 'react'
import {
  CButton,
  CCard,
  CCardImg,
  CCardBody,
  CCardText,
  CCardTitle,
} from '@coreui/react'
import styled from 'styled-components'

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
`

type Props = {
 history: any
 location: any
 match: any
 staticContext?: any
}

export const Home = (props: Props) => {
  return (
    <Wrapper>
      <CCard style={{ width: '25rem', padding: '20px 20px 0px 20px', boxShadow: '5px 10px #8888880d', border: '1px solid #88888833' }}>
        <CCardImg src="/logo-color.png" />
        <CCardBody>
          <CCardTitle>San Diego, California</CCardTitle>
          <CCardText>
            Epsilon Risk Management (ERM) is a quantitative investment management firm dedicated to producing excess risk adjusted returns for its investors through the rigorous application of mathematics.
          </CCardText>
          <CButton color="primary" href="/#/dashboard">Investor Login</CButton>
        </CCardBody>
      </CCard>
    </Wrapper>
  )
}
