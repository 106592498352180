import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  wrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    backgroundColor: "#3c4b64",
    transition: "all 2s ease",
    opacity: (props: Props) => (props.isLoading ? 0.5 : 0),
    zIndex: (props: Props) => (props.isLoading ? 1000 : -1)
  },
  spinner: {
    position: "absolute",
    top: "calc(50% - 12.5px)",
    left: "calc(50% - 12.5px)",
    width: "25px",
    height: "25px",
    borderTop: "8px solid aliceblue",
    borderRight: "8px solid aliceblue",
    borderBottom: "8px solid aliceblue",
    borderLeft: "8px solid #8c618d",
    borderRadius: "50%",
    animationDuration: "3s",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
    animationName: "$spin"
  },
  text: {
    position: "absolute",
    top: "calc(50% + 35px)",
    left: "calc(50% - 25px)",
    fontFamily: "sans-serif",
    color: "white",
    letterSpacing: "0.1em"
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)", borderLeft: "8px solid deeppink" },
    "25%": { transform: "rotate(360deg)", borderLeft: "8px solid gold" },
    "50%": { transform: "rotate(720deg)", borderLeft: "8px solid palegreen" },
    "75%": { transform: "rotate(1080deg)", borderLeft: "8px solid aqua" },
    "100%": { transform: "rotate(1440deg)", borderLeft: "8px solid deeppink" }
  }
}));

export type Props = {
  isLoading: boolean;
};

const Loading = ({ isLoading }: Props) => {
  const classes = useStyles({ isLoading });
  return (
    <div className={classes.wrapper}>
      <div className={classes.spinner} />
      <div className={classes.text}>loading...</div>
    </div>
  );
};

export default Loading;
