import React from 'react'
import {
  Table,
} from 'reactstrap'
import {
  QueryRenderer
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useRelayEnvironment } from 'relay-hooks'

import { OrdersQueryResponse } from './__generated__/OrdersQuery.graphql'
import Loading from '../../../../components/Loading'

type OrderType = {
  readonly id: string;
  readonly createdAt: string;
  readonly marketID: string;
  readonly customID: string;
  readonly playID: string;
  readonly instrument: string;
  readonly side: string;
  readonly volume: number;
  readonly price: number;
}

const Order = (
  {
    index,
    order: {
      id,
      createdAt,
      customID,
      instrument,
      side,
      volume,
      price,
    }
  }:
    {
      index: number
      order: OrderType
    }
) => {
  return (
    <tr key={index}>
      <td style={{ textAlign: 'center' }}>
        {id}
      </td>
      <td style={{ textAlign: 'center' }}>
        {createdAt}
      </td>
      <td style={{ textAlign: 'center' }}>
        {customID}
      </td>
      <td style={{ textAlign: 'center' }}>
        {instrument}
      </td>
      <td style={{ textAlign: 'center' }}>
        {side}
      </td>
      <td style={{ textAlign: 'center' }}>
        {volume} {instrument.split("-")[0]}
      </td>
      <td style={{ textAlign: 'center' }}>
        {price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
      </td>
    </tr>
  )
}

export interface OrdersQueryProps {
  accountID: string;
  limit: number;
  skip: number;
}

const OrdersQuery = ({ accountID, skip, limit }: OrdersQueryProps) => {
  const environment = useRelayEnvironment()

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query OrdersQuery($accountID: ID!, $skip: Int!, $limit: Int!) {
          accountOrders(accountID: $accountID, skip: $skip, limit: $limit) {
            id
            createdAt
            marketID
            customID
            playID
            instrument
            side
            volume
            price
          }
        }
      `}
      variables={{
        accountID,
        skip,
        limit,
      }}
      render={({ error, props }) => {
        if (error) {
          console.error(error)
          return <>Error</>
        }

        let orders: OrdersQueryResponse["accountOrders"] = []
        if (props) {
          orders = (props as OrdersQueryResponse).accountOrders
        }

        return (
          <>
            {!props && !error ? <tr><td colSpan={7}><Loading isLoading={!props && !error} /></td></tr> : <></>}
            {orders.map((o, idx) => (
              <Order
                key={idx}
                index={idx}
                order={o}
              />
            ))}
          </>
        )
      }}
    />
  )
}

export interface OrdersProps {
  accountID: string;
  limit: number;
  skip: number;
}

export const Orders = ({ accountID, limit, skip }: OrdersProps) => {
  return (
    <Table responsive className="center">
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>
            ID
          </th>
          <th style={{ textAlign: 'center' }}>
            Created At
          </th>
          <th style={{ textAlign: 'center' }}>
            Custom Order ID
          </th>
          <th style={{ textAlign: 'center' }}>
            Instrument
          </th>
          <th style={{ textAlign: 'center' }}>
            Side
          </th>
          <th style={{ textAlign: 'center' }}>
            Volume
          </th>
          <th style={{ textAlign: 'center' }}>
            Price
          </th>
        </tr>
      </thead>
      <tbody>
        <OrdersQuery
          accountID={accountID}
          limit={limit}
          skip={skip}
        />
      </tbody>
    </Table>
  )
}
