import Dashboard from './views/dashboard/Dashboard';
import { Home } from './views/pages/home/';
import Accounts from './views/accounts'

const routes = [
  { path: '/', exact: true, name: 'Home', component: Home },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/accounts/:accountID', name: 'Accounts', component: Accounts },
];

export default routes;
