import React, { useEffect } from 'react'
import {
  QueryRenderer
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useRelayEnvironment } from 'relay-hooks'
import { stockChart } from 'highcharts/highstock';

import { ChartAccountCumulativeReturnsQueryResponse } from './__generated__/ChartAccountCumulativeReturnsQuery.graphql'
import Loading from '../../../../../components/Loading'

type CumulativeReturnsChartProps = {
  loading: boolean;
  data: number[][];
  error: Error;
}
const CumulativeReturnsChart = ({
  error,
  data,
  // TODO: add loader
  // loading
}: CumulativeReturnsChartProps) => {
  useEffect(() => {
    stockChart({
      chart: {
        renderTo: 'cumulativeReturnsChartWrapper',
      },

      rangeSelector: {
        selected: 1
      },

      title: {
        text: 'Cumulative Account Value (Daily)',
      },

      series: [{
        type: 'line',
        name: '',
        data: data,
        tooltip: {
          valueDecimals: 2
        }
      }],

      //time: {
      //timezoneOffset: -8,
      //}
    })
  }, [data])

  if (error) {
    console.error('cumulative returns chart error', error)
    return <></>
  }

  return (
    <></>
  )
}

export interface ChartProps {
  accountID: string;
}

export const Chart = ({ accountID }: ChartProps) => {
  const environment = useRelayEnvironment()

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query ChartAccountCumulativeReturnsQuery($accountID: ID!) {
          accountCumulativeReturns(accountID: $accountID) {
            data
          }
        }
      `}
      variables={{
        accountID
      }}
      render={({ error, props }) => {
        if (error) {
          console.error(error)
          return <>Error</>
        }

        let data: number[][] = [];
        if (props) {
          if ((props as ChartAccountCumulativeReturnsQueryResponse).accountCumulativeReturns.data) {
            // @ts-ignore
            data = (props as ChartAccountCumulativeReturnsQueryResponse).accountCumulativeReturns.data
            let newData: number[][] = [];
            let i = data.length - 1;
            while (i >= 0) {
              newData.push([data[i][0] * 1000, data[i][1]])
              i--
            }
            data = newData
          }
        }

        return (
          <>
            <Loading isLoading={!props && !error} />
            <div id="cumulativeReturnsChartWrapper">
              <CumulativeReturnsChart
                error={error}
                data={data}
                loading={!props}
              />
            </div>
          </>
        )
      }}
    />
  )
}
