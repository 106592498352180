/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type BalancesQueryVariables = {
    userID: string;
    skip: number;
    limit: number;
};
export type BalancesQueryResponse = {
    readonly balances: ReadonlyArray<{
        readonly accountID: string;
        readonly instrument: string;
        readonly currency: string;
        readonly netAssetValue: number;
        readonly unrealizedPNL: number;
        readonly realizedPNL: number;
        readonly balance: number;
    }>;
};
export type BalancesQuery = {
    readonly response: BalancesQueryResponse;
    readonly variables: BalancesQueryVariables;
};



/*
query BalancesQuery(
  $userID: ID!
  $skip: Int!
  $limit: Int!
) {
  balances(userID: $userID, skip: $skip, limit: $limit) {
    accountID
    instrument
    currency
    netAssetValue
    unrealizedPNL
    realizedPNL
    balance
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userID"
},
v3 = [
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "skip",
    "variableName": "skip"
  },
  {
    "kind": "Variable",
    "name": "userID",
    "variableName": "userID"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountID",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instrument",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netAssetValue",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unrealizedPNL",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "realizedPNL",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "balance",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BalancesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Balance",
        "kind": "LinkedField",
        "name": "balances",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BalancesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Balance",
        "kind": "LinkedField",
        "name": "balances",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff52d34619d88ea668cae58d63b97921",
    "id": null,
    "metadata": {},
    "name": "BalancesQuery",
    "operationKind": "query",
    "text": "query BalancesQuery(\n  $userID: ID!\n  $skip: Int!\n  $limit: Int!\n) {\n  balances(userID: $userID, skip: $skip, limit: $limit) {\n    accountID\n    instrument\n    currency\n    netAssetValue\n    unrealizedPNL\n    realizedPNL\n    balance\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '4e4107a6db12984efde6b8d31995ca87';
export default node;
