import React from 'react'
import {
    Table,
} from 'reactstrap'
import {
    QueryRenderer
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useRelayEnvironment } from 'relay-hooks'

import { AccountBalancesQueryResponse } from './__generated__/AccountBalancesQuery.graphql'
import Loading from '../../../../components/Loading'

type BalanceType = {
    readonly accountID: string;
    readonly instrument: string;
    readonly currency: string;
    readonly netAssetValue: number;
    readonly unrealizedPNL: number;
    readonly realizedPNL: number;
    readonly balance: number;
}

const Balance = (
    {
        index,
        balance: {
            accountID,
            instrument,
            currency,
            netAssetValue,
            unrealizedPNL,
            realizedPNL,
            balance,
        }
    }:
        {
            index: number
            balance: BalanceType
        }
) => {
    return (
        <tr key={index}>
            <td style={{ textAlign: 'center' }}>
                {accountID}
            </td>
            <td style={{ textAlign: 'center' }}>
                {instrument}
            </td>
            <td style={{ textAlign: 'center' }}>
                {currency}
            </td>
            <td style={{ textAlign: 'right' }}>
                {netAssetValue.toLocaleString('en-US', { style: 'currency', currency })}
            </td>
            <td style={{ textAlign: 'right' }}>
                {unrealizedPNL.toLocaleString('en-US', { style: 'currency', currency })}
            </td>
            <td style={{ textAlign: 'right' }}>
                {realizedPNL.toLocaleString('en-US', { style: 'currency', currency })}
            </td>
            <td style={{ textAlign: 'right' }}>
                {balance.toLocaleString('en-US', { style: 'currency', currency })}
            </td>
        </tr>
    )
}

export interface BalancesQueryProps {
    accountID: string;
}

const BalancesQuery = ({ accountID }: BalancesQueryProps) => {
    const environment = useRelayEnvironment()

    return (
        <QueryRenderer
            environment={environment}
            query={graphql`
        query AccountBalancesQuery($accountID: ID!) {
          accountBalances(accountID: $accountID) {
            accountID
            instrument
            currency
            netAssetValue
            unrealizedPNL
            realizedPNL
            balance
          }
        }
      `}
            variables={{
                accountID,
                skip: 0,
                limit: 10,
            }}
            render={({ error, props }) => {
                if (error) {
                    console.error(error)
                    return <>Error</>
                }

                let balances: AccountBalancesQueryResponse["accountBalances"] = []
                if (props) {
                    balances = (props as AccountBalancesQueryResponse).accountBalances
                }

                return (
                    <>
                        {!props && !error ? <tr><td colSpan={7}><Loading isLoading={!props && !error} /></td></tr> : <></>}
                        {balances.map((b, idx) => (
                            <Balance
                                key={idx}
                                index={idx}
                                balance={b}
                            />
                        ))}
                        <tr>
                            <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                TOTAL
                            </td>
                            <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            </td>
                            <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            </td>
                            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                {balances.reduce((sum, b) => {
                                    return sum + b.netAssetValue;
                                }, 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                            </td>
                            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                {balances.reduce((sum, b) => {
                                    return sum + b.unrealizedPNL;
                                }, 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                            </td>
                            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                {balances.reduce((sum, b) => {
                                    return sum + b.realizedPNL;
                                }, 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                            </td>
                            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>
                                {balances.reduce((sum, b) => {
                                    return sum + b.balance;
                                }, 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                            </td>
                        </tr>
                    </>
                )
            }}
        />
    )
}

export interface BalancesProps {
    accountID: string;
}

export const Balances = ({ accountID }: BalancesProps) => {
    return (
        <Table responsive className="center">
            <thead>
                <tr>
                    <th style={{ textAlign: 'center' }}>
                        Account ID
                    </th>
                    <th style={{ textAlign: 'center' }}>
                        Instrument
                    </th>
                    <th style={{ textAlign: 'center' }}>
                        Currency
                    </th>
                    <th style={{ textAlign: 'right' }}>
                        Net Asset Value
                    </th>
                    <th style={{ textAlign: 'right' }}>
                        Unrealized PNL
                    </th>
                    <th style={{ textAlign: 'right' }}>
                        Realized PNL
                    </th>
                    <th style={{ textAlign: 'right' }}>
                        Balance
                    </th>
                </tr>
            </thead>
            <tbody>
                <BalancesQuery accountID={accountID} />
            </tbody>
        </Table>
    )
}
