/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PricesQueryVariables = {
    accountID: string;
    skip: number;
    limit: number;
};
export type PricesQueryResponse = {
    readonly accountPrices: ReadonlyArray<{
        readonly id: string;
        readonly timestamp: string;
        readonly exchange: string;
        readonly period: string;
        readonly instrument: string;
        readonly base: string;
        readonly quote: string;
        readonly price: number;
    }>;
};
export type PricesQuery = {
    readonly response: PricesQueryResponse;
    readonly variables: PricesQueryVariables;
};



/*
query PricesQuery(
  $accountID: ID!
  $skip: Int!
  $limit: Int!
) {
  accountPrices(accountID: $accountID, skip: $skip, limit: $limit) {
    id
    timestamp
    exchange
    period
    instrument
    base
    quote
    price
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountID"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountID",
        "variableName": "accountID"
      },
      {
        "kind": "Variable",
        "name": "limit",
        "variableName": "limit"
      },
      {
        "kind": "Variable",
        "name": "skip",
        "variableName": "skip"
      }
    ],
    "concreteType": "Price",
    "kind": "LinkedField",
    "name": "accountPrices",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timestamp",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "exchange",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "period",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "instrument",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "base",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "quote",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PricesQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "PricesQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "a4568e7727d594a506c5591ab2573976",
    "id": null,
    "metadata": {},
    "name": "PricesQuery",
    "operationKind": "query",
    "text": "query PricesQuery(\n  $accountID: ID!\n  $skip: Int!\n  $limit: Int!\n) {\n  accountPrices(accountID: $accountID, skip: $skip, limit: $limit) {\n    id\n    timestamp\n    exchange\n    period\n    instrument\n    base\n    quote\n    price\n  }\n}\n"
  }
};
})();
(node as any).hash = '5e44bafa440eff64081d3ed9fb5073a4';
export default node;
