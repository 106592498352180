import React from 'react'
import {
  Table,
} from 'reactstrap'
import {
  QueryRenderer
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useRelayEnvironment } from 'relay-hooks'

import { ForecastsQueryResponse } from './__generated__/ForecastsQuery.graphql'
import Loading from '../../../../components/Loading'

type ForecastType = {
  readonly id: string;
  readonly createdAt: string;
  readonly period: string;
  readonly instrument: string;
  readonly VWAP: number;
  readonly VWAPStdDev: number;
}

const Forecast = (
  {
    index,
    forecast: {
      id,
      createdAt,
      period,
      instrument,
      VWAP,
      VWAPStdDev,
    }
  }:
    {
      index: number
      forecast: ForecastType
    }
) => {
  return (
    <tr key={index}>
      <td style={{ textAlign: 'center' }}>
        {id}
      </td>
      <td style={{ textAlign: 'center' }}>
        {createdAt}
      </td>
      <td style={{ textAlign: 'center' }}>
        {period}
      </td>
      <td style={{ textAlign: 'center' }}>
        {instrument}
      </td>
      <td style={{ textAlign: 'center' }}>
        {VWAP.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
      </td>
      <td style={{ textAlign: 'center' }}>
        {VWAPStdDev.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
      </td>
    </tr>
  )
}

export interface ForecastsQueryProps {
  accountID: string;
  skip: number;
  limit: number;
}

const ForecastsQuery = ({ accountID, skip, limit }: ForecastsQueryProps) => {
  const environment = useRelayEnvironment()

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query ForecastsQuery($accountID: ID!, $skip: Int!, $limit: Int!) {
          accountForecasts(accountID: $accountID, skip: $skip, limit: $limit) {
            id
            createdAt
            period
            instrument
            VWAP
            VWAPStdDev
          }
        }
      `}
      variables={{
        accountID,
        skip,
        limit,
      }}
      render={({ error, props }) => {
        if (error) {
          console.error(error)
          return <>Error</>
        }

        let forecasts: ForecastsQueryResponse["accountForecasts"] = []
        if (props) {
          forecasts = (props as ForecastsQueryResponse).accountForecasts
        }

        return (
          <>
            {!props && !error ? <tr><td colSpan={6}><Loading isLoading={!props && !error} /></td></tr> : <></>}
            {forecasts.map((f, idx) => (
              <Forecast
                key={idx}
                index={idx}
                forecast={f}
              />
            ))}
          </>
        )
      }}
    />
  )
}

export interface ForecastsProps {
  accountID: string;
  skip: number;
  limit: number;
}

export const Forecasts = ({ accountID, skip, limit }: ForecastsProps) => {
  return (
    <Table responsive className="center">
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>
            ID
                    </th>
          <th style={{ textAlign: 'center' }}>
            Created At
                    </th>
          <th style={{ textAlign: 'center' }}>
            Period
                    </th>
          <th style={{ textAlign: 'center' }}>
            Instrument
                    </th>
          <th style={{ textAlign: 'center' }}>
            VWAP
                    </th>
          <th style={{ textAlign: 'center' }}>
            VWAP Std. Dev.
                    </th>
        </tr>
      </thead>
      <tbody>
        <ForecastsQuery
          accountID={accountID}
          skip={skip}
          limit={limit}
        />
      </tbody>
    </Table>
  )
}
