import React, { useContext } from 'react'
import {
    Table,
} from 'reactstrap'
import {
    QueryRenderer
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useRelayEnvironment } from 'relay-hooks'

import { SummaryQueryResponse } from './__generated__/SummaryQuery.graphql'
import { UserContext } from '../../../contexts/user'
import Loading from '../../../components/Loading'

const SummaryQuery = () => {
    const environment = useRelayEnvironment()
    const user = useContext(UserContext)

    return (
        <QueryRenderer
            environment={environment}
            query={graphql`
        query SummaryQuery($userID: ID!) {
          userSummary(userID: $userID) {
            totalReturn
            cagr
            sharpe
            maxDD
            betaGSPC
            betaUnderlying
          }
        }
      `}
            variables={{
                userID: user?.id,
            }}
            render={({ error, props }) => {
                if (error) {
                    console.error(error)
                    return <>Error</>
                }

                let totalReturn = -1
                let cagr = -1
                let sharpe = -1
                let maxDD = -1

                if (props) {
                    totalReturn = (props as SummaryQueryResponse).userSummary.totalReturn
                    cagr = (props as SummaryQueryResponse).userSummary.cagr
                    sharpe = (props as SummaryQueryResponse).userSummary.sharpe
                    maxDD = (props as SummaryQueryResponse).userSummary.maxDD
                }

                return (
                    <>
                        {!props && !error ? <tr><td colSpan={6}><Loading isLoading={!props && !error} /></td></tr> : <></>}
                        <tr>
                            <td>
                                {(totalReturn * 100).toFixed(4)}%
              </td>
                            <td>
                                {(cagr * 100).toFixed(4)}%
              </td>
                            <td>
                                {(maxDD * 100).toFixed(4)}%
              </td>
                            <td>
                                {sharpe.toFixed(4)}
                            </td>
                            <td>
                                Not Yet Implemented
              </td>
                            <td>
                                Not Yet Implemented
              </td>
                        </tr>
                    </>
                )
            }}
        />
    )
}

export const Summary = () => {
    return (
        <Table responsive className="center" style={{ textAlign: 'center' }}>
            <thead>
                <tr>
                    <th>
                        Total Return
          </th>
                    <th>
                        Compound Annual Growth Rate
          </th>
                    <th>
                        Max Draw Down
          </th>
                    <th>
                        Sharpe (Rf = 3%)
          </th>
                    <th>
                        Beta (^GSPC)
          </th>
                    <th>
                        Beta (underlying)
          </th>
                </tr>
            </thead>
            <tbody>
                <SummaryQuery />
            </tbody>
        </Table>
    )
}
