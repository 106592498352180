import React, { useState } from 'react'
import {
  useParams
} from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from '@coreui/react'

import Performance from './Performance'
import Current from './Current'
import Settings from './Settings'
import History from './History'

const Accounts = () => {
  const [modal, setModal] = useState(true)
  const { accountID } = useParams<{ accountID: string }>();

  return (
    <>
      <CModal show={modal} onClose={setModal}>
        <CModalHeader closeButton>
          <CModalTitle>Warning</CModalTitle>
        </CModalHeader>
        <CModalBody>The information provided on this page may not be accurate and should not be relied upon.</CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setModal(false)}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
      <CCard style={{ position: "relative" }}>
        <CCardHeader>
          <h1>Performance</h1>
        </CCardHeader>
        <CCardBody>
          <Performance accountID={accountID} />
        </CCardBody>
      </CCard>
      <CCard style={{ position: "relative" }}>
        <CCardHeader>
          <h1>Current</h1>
        </CCardHeader>
        <CCardBody>
          <Current accountID={accountID} />
        </CCardBody>
      </CCard>
      <CCard style={{ position: "relative" }}>
        <CCardHeader>
          <h1>History</h1>
        </CCardHeader>
        <CCardBody>
          <History accountID={accountID} />
        </CCardBody>
      </CCard>
      <CCard style={{ position: "relative" }}>
        <CCardHeader>
          <h1>Settings</h1>
        </CCardHeader>
        <CCardBody>
          <Settings accountID={accountID} />
        </CCardBody>
      </CCard>
    </>
  )
}

export default Accounts
