/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ForecastsQueryVariables = {
    accountID: string;
    skip: number;
    limit: number;
};
export type ForecastsQueryResponse = {
    readonly accountForecasts: ReadonlyArray<{
        readonly id: string;
        readonly createdAt: string;
        readonly period: string;
        readonly instrument: string;
        readonly VWAP: number;
        readonly VWAPStdDev: number;
    }>;
};
export type ForecastsQuery = {
    readonly response: ForecastsQueryResponse;
    readonly variables: ForecastsQueryVariables;
};



/*
query ForecastsQuery(
  $accountID: ID!
  $skip: Int!
  $limit: Int!
) {
  accountForecasts(accountID: $accountID, skip: $skip, limit: $limit) {
    id
    createdAt
    period
    instrument
    VWAP
    VWAPStdDev
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountID"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountID",
        "variableName": "accountID"
      },
      {
        "kind": "Variable",
        "name": "limit",
        "variableName": "limit"
      },
      {
        "kind": "Variable",
        "name": "skip",
        "variableName": "skip"
      }
    ],
    "concreteType": "Forecast",
    "kind": "LinkedField",
    "name": "accountForecasts",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "period",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "instrument",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "VWAP",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "VWAPStdDev",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ForecastsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ForecastsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "9ced57bc7a312cadd26a0d542b6e9746",
    "id": null,
    "metadata": {},
    "name": "ForecastsQuery",
    "operationKind": "query",
    "text": "query ForecastsQuery(\n  $accountID: ID!\n  $skip: Int!\n  $limit: Int!\n) {\n  accountForecasts(accountID: $accountID, skip: $skip, limit: $limit) {\n    id\n    createdAt\n    period\n    instrument\n    VWAP\n    VWAPStdDev\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f90bde082bd31a10af804b4b8fbccd1e';
export default node;
