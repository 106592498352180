import React, { useContext } from 'react'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { UserContext } from '../contexts/user'
import { API_URL } from '../config'

const TheHeaderDropdown = () => {
  const user = useContext(UserContext)

  const logout = async () => {
    let headers = {
      'Content-Type': 'application/json'
    }
    let sessionToken = localStorage.getItem('session-token')
    if (sessionToken && sessionToken !== '') {
      headers['Authorization'] = `Bearer ${sessionToken}`
    }

    const res = await fetch(`${API_URL}/logout`, {
      method: 'POST',
      mode: 'cors',
      headers,
    })

    if (res.status !== 200) {
      console.error(`non 200 ${res.status}`)
      return
    }

    localStorage.removeItem('session-token')
    localStorage.removeItem('session-expires')
    localStorage.removeItem('user')
    user?.clearUser()
  }

  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      // @ts-ignore
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <p style={{ marginBottom: '0px' }}>{user?.email}</p>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>Account</strong>
        </CDropdownItem>
        {/*
        <CDropdownItem>
          <CIcon name="cil-bell" className="mfe-2" />
          Updates
          <CBadge color="info" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-envelope-open" className="mfe-2" />
          Messages
          <CBadge color="success" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-task" className="mfe-2" />
          Tasks
          <CBadge color="danger" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-comment-square" className="mfe-2" />
          Comments
          <CBadge color="warning" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>Settings</strong>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-user" className="mfe-2" />Profile
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-settings" className="mfe-2" />
          Settings
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-credit-card" className="mfe-2" />
          Payments
          <CBadge color="secondary" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-file" className="mfe-2" />
          Projects
          <CBadge color="primary" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem divider />
        */}
        <CDropdownItem onClick={() => { void logout() }}>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Log Out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheHeaderDropdown
