import React, { useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCardFooter,
} from '@coreui/react'
import {
  Col,
  Input,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

import { Positions } from './Positions'
import { Balances } from './Balances'
import { Risk } from './Risk'
import { Orders } from './Orders'

export interface CurrentProps {
  accountID: string;
}

const Current = ({ accountID }: CurrentProps) => {
  const [ordersSkip, setOrdersSkip] = useState(0)
  const [ordersLimit, setOrdersLimit] = useState(10)

  return (
    <>
      <CCard style={{ position: "relative" }}>
        <CCardHeader>
          <h1>Positions</h1>
        </CCardHeader>
        <CCardBody>
          <Positions accountID={accountID} />
        </CCardBody>
        <CCardFooter>
          Updated every 15 minutes.
        </CCardFooter>
      </CCard>
      <CCard style={{ position: "relative" }}>
        <CCardHeader>
          <h1>Balances</h1>
        </CCardHeader>
        <CCardBody>
          <Balances accountID={accountID} />
        </CCardBody>
        <CCardFooter>
          Updated every 15 minutes.
        </CCardFooter>
      </CCard>
      <CCard style={{ position: "relative" }}>
        <CCardHeader
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <h1>Orders</h1>
          <div>
            <Label
              for="limitSelect"
              sm={12}
            >
              Show
            </Label>
            <Col sm={12}>
              <Input
                id="limitSelect"
                name="select"
                type="select"
                value={ordersLimit}
                onChange={e => {
                  setOrdersLimit(Number(e.target.value))
                }}
              >
                <option value={10}>
                  10
                </option>
                <option value={25}>
                  25
                </option>
                <option value={50}>
                  50
                </option>
              </Input>
            </Col>
          </div>
        </CCardHeader>
        <CCardBody>
          <Orders
            accountID={accountID}
            skip={ordersSkip}
            limit={ordersLimit}
          />
        </CCardBody>
        <CCardFooter
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          Updated every 15 minutes.
          <Pagination>
            <PaginationItem
              disabled={ordersSkip === 0}
              onClick={() => {
                if (ordersSkip === 0) return;
                const tmpSkip = Math.max(
                  0,
                  ordersSkip - ordersLimit
                );
                setOrdersSkip(tmpSkip)
              }}
            >
              <PaginationLink previous href="#" />
            </PaginationItem>
            <PaginationItem
              disabled={false}
              onClick={() => {
                const tmpSkip = ordersSkip + ordersLimit;
                setOrdersSkip(tmpSkip);
              }}
            >
              <PaginationLink next href="#" />
            </PaginationItem>
          </Pagination>
        </CCardFooter>
      </CCard>
      <CCard style={{ position: "relative" }}>
        <CCardHeader>
          <h1>Value At Risk</h1>
        </CCardHeader>
        <CCardBody>
          <Risk accountID={accountID} />
        </CCardBody>
        <CCardFooter>
          Updated every 15 minutes.
        </CCardFooter>
      </CCard>
    </>
  )
}

export default Current
