import React from 'react'
import {
  Table,
} from 'reactstrap'
import {
  QueryRenderer
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useRelayEnvironment } from 'relay-hooks'

import { PricesQueryResponse } from './__generated__/PricesQuery.graphql'
import Loading from '../../../../components/Loading'

type PriceType = {
  readonly id: string;
  readonly timestamp: string;
  readonly exchange: string;
  readonly period: string;
  readonly instrument: string;
  readonly base: string;
  readonly quote: string;
  readonly price: number;
}

const Price = (
  {
    index,
    price: {
      id,
      timestamp,
      exchange,
      period,
      instrument,
      base,
      quote,
      price,
    }
  }:
    {
      index: number
      price: PriceType
    }
) => {
  return (
    <tr key={index}>
      <td style={{ textAlign: 'center' }}>
        {id}
      </td>
      <td style={{ textAlign: 'center' }}>
        {timestamp}
      </td>
      <td style={{ textAlign: 'center' }}>
        {exchange}
      </td>
      <td style={{ textAlign: 'center' }}>
        {period}
      </td>
      <td style={{ textAlign: 'center' }}>
        {instrument}
      </td>
      <td style={{ textAlign: 'center' }}>
        {base}
      </td>
      <td style={{ textAlign: 'center' }}>
        {quote}
      </td>
      <td style={{ textAlign: 'center' }}>
        {price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
      </td>
    </tr>
  )
}

export interface PricesQueryProps {
  accountID: string;
  skip: number;
  limit: number;
}

const PricesQuery = ({ accountID, skip, limit }: PricesQueryProps) => {
  const environment = useRelayEnvironment()

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query PricesQuery($accountID: ID!, $skip: Int!, $limit: Int!) {
          accountPrices(accountID: $accountID, skip: $skip, limit: $limit) {
            id
            timestamp
            exchange
            period
            instrument
            base
            quote
            price
          }
        }
      `}
      variables={{
        accountID,
        skip,
        limit,
      }}
      render={({ error, props }) => {
        if (error) {
          console.error(error)
          return <>Error</>
        }

        let prices: PricesQueryResponse["accountPrices"] = []
        if (props) {
          prices = (props as PricesQueryResponse).accountPrices
        }

        return (
          <>
            {!props && !error ? <tr><td colSpan={8}><Loading isLoading={!props && !error} /></td></tr> : <></>}
            {prices.map((p, idx) => (
              <Price
                key={idx}
                index={idx}
                price={p}
              />
            ))}
          </>
        )
      }}
    />
  )
}

export interface PricesProps {
  accountID: string;
  skip: number;
  limit: number;
}

export const Prices = ({ accountID, skip, limit }: PricesProps) => {
  return (
    <Table responsive className="center">
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>
            ID
          </th>
          <th style={{ textAlign: 'center' }}>
            Timestamp
          </th>
          <th style={{ textAlign: 'center' }}>
            Exchange
          </th>
          <th style={{ textAlign: 'center' }}>
            Period
          </th>
          <th style={{ textAlign: 'center' }}>
            Instrument
          </th>
          <th style={{ textAlign: 'center' }}>
            Base
          </th>
          <th style={{ textAlign: 'center' }}>
            Quote
          </th>
          <th style={{ textAlign: 'center' }}>
            Price
          </th>
        </tr>
      </thead>
      <tbody>
        <PricesQuery
          accountID={accountID}
          skip={skip}
          limit={limit}
        />
      </tbody>
    </Table>
  )
}
