/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AccountTradingParametersQueryVariables = {
    accountID: string;
};
export type AccountTradingParametersQueryResponse = {
    readonly accountConfigs: ReadonlyArray<{
        readonly accountID: string;
        readonly forecastNumTradesPerSide: number;
        readonly percentCapitalToAllocate: number;
        readonly liqMgmtNumTradesPerSide: number;
        readonly liqMgmtNumStdDevsSpread: number;
        readonly forecastTotalNumStdDevSpread: number;
        readonly minPercentDistFromVWAP: number;
        readonly minPercentDistFromAVGEntry: number;
        readonly forecastCloseoutThresholdPercentage: number;
        readonly previousDayMultiplier: number;
        readonly stdDevMultiplier: number;
    }>;
};
export type AccountTradingParametersQuery = {
    readonly response: AccountTradingParametersQueryResponse;
    readonly variables: AccountTradingParametersQueryVariables;
};



/*
query AccountTradingParametersQuery(
  $accountID: ID!
) {
  accountConfigs(accountID: $accountID) {
    accountID
    forecastNumTradesPerSide
    percentCapitalToAllocate
    liqMgmtNumTradesPerSide
    liqMgmtNumStdDevsSpread
    forecastTotalNumStdDevSpread
    minPercentDistFromVWAP
    minPercentDistFromAVGEntry
    forecastCloseoutThresholdPercentage
    previousDayMultiplier
    stdDevMultiplier
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountID",
    "variableName": "accountID"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountID",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "forecastNumTradesPerSide",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percentCapitalToAllocate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "liqMgmtNumTradesPerSide",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "liqMgmtNumStdDevsSpread",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "forecastTotalNumStdDevSpread",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minPercentDistFromVWAP",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minPercentDistFromAVGEntry",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "forecastCloseoutThresholdPercentage",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "previousDayMultiplier",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stdDevMultiplier",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountTradingParametersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Config",
        "kind": "LinkedField",
        "name": "accountConfigs",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountTradingParametersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Config",
        "kind": "LinkedField",
        "name": "accountConfigs",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cceeffc9085dfd173ef7477c7890c566",
    "id": null,
    "metadata": {},
    "name": "AccountTradingParametersQuery",
    "operationKind": "query",
    "text": "query AccountTradingParametersQuery(\n  $accountID: ID!\n) {\n  accountConfigs(accountID: $accountID) {\n    accountID\n    forecastNumTradesPerSide\n    percentCapitalToAllocate\n    liqMgmtNumTradesPerSide\n    liqMgmtNumStdDevsSpread\n    forecastTotalNumStdDevSpread\n    minPercentDistFromVWAP\n    minPercentDistFromAVGEntry\n    forecastCloseoutThresholdPercentage\n    previousDayMultiplier\n    stdDevMultiplier\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '888748531d02a7c3ba3e47c4e3d3e2df';
export default node;
