/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AccountBalancesQueryVariables = {
    accountID: string;
};
export type AccountBalancesQueryResponse = {
    readonly accountBalances: ReadonlyArray<{
        readonly accountID: string;
        readonly instrument: string;
        readonly currency: string;
        readonly netAssetValue: number;
        readonly unrealizedPNL: number;
        readonly realizedPNL: number;
        readonly balance: number;
    }>;
};
export type AccountBalancesQuery = {
    readonly response: AccountBalancesQueryResponse;
    readonly variables: AccountBalancesQueryVariables;
};



/*
query AccountBalancesQuery(
  $accountID: ID!
) {
  accountBalances(accountID: $accountID) {
    accountID
    instrument
    currency
    netAssetValue
    unrealizedPNL
    realizedPNL
    balance
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountID",
    "variableName": "accountID"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountID",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instrument",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "netAssetValue",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unrealizedPNL",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "realizedPNL",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "balance",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountBalancesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Balance",
        "kind": "LinkedField",
        "name": "accountBalances",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountBalancesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Balance",
        "kind": "LinkedField",
        "name": "accountBalances",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9b15b2abcf4d9354e06c19d3b585869b",
    "id": null,
    "metadata": {},
    "name": "AccountBalancesQuery",
    "operationKind": "query",
    "text": "query AccountBalancesQuery(\n  $accountID: ID!\n) {\n  accountBalances(accountID: $accountID) {\n    accountID\n    instrument\n    currency\n    netAssetValue\n    unrealizedPNL\n    realizedPNL\n    balance\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '11b83aee0334ead959821764f2764dd7';
export default node;
