import React, { useEffect } from 'react'
import {
  QueryRenderer
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useRelayEnvironment } from 'relay-hooks'
import { stockChart } from 'highcharts/highstock';

import { LevAndPriceErrQueryResponse } from './__generated__/LevAndPriceErrQuery.graphql'
import Loading from '../../../../components/Loading'

type LevAndPriceErrChartProps = {
  loading: boolean;
  levData: number[][];
  priceErrData: number[][];
  error: Error;
}
const LevAndPriceErrChart = ({
  error,
  levData,
  priceErrData,
  // TODO: add loader
  // loading
}: LevAndPriceErrChartProps) => {
  useEffect(() => {
    stockChart({
      chart: {
        renderTo: 'levAndPriceErrChartWrapper',
      },

      rangeSelector: {
        selected: 1
      },

      title: {
        text: 'Leverage and Price Errors',
      },

      yAxis: [
        {
          title: {
            text: 'Leverage'
          }
        },
        {
          title: {
            text: 'Price Error'
          },
          opposite: false
        }
      ],

      series: [
        {
          type: 'line',
          name: 'Leverage',
          data: levData,
          tooltip: {
            valueDecimals: 2
          },
          yAxis: 0,
        },
        {
          type: 'line',
          name: 'Price Error',
          data: priceErrData,
          tooltip: {
            valueDecimals: 2
          },
          yAxis: 1,
        },
      ],

      //time: {
      //timezoneOffset: -8,
      //}
    })
  }, [levData, priceErrData])

  if (error) {
    console.error('chart error', error)
    return <></>
  }

  return (
    <></>
  )
}

export interface LevAndPriceErrProps {
  accountID: string;
}

export const LevAndPriceErr = ({ accountID }: LevAndPriceErrProps) => {
  const environment = useRelayEnvironment()

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query LevAndPriceErrQuery($accountID: ID!) {
          accountLevAndPriceErr(accountID: $accountID) {
            lev
            priceErr
          }
        }
      `}
      variables={{
        accountID
      }}
      render={({ error, props }) => {
        if (error) {
          console.error(error)
          return <>Error</>
        }

        let levData: number[][] = [];
        let priceErrData: number[][] = [];
        if (props) {
          if ((props as LevAndPriceErrQueryResponse).accountLevAndPriceErr.lev) {
            // @ts-ignore
            levData = (props as LevAndPriceErrQueryResponse).accountLevAndPriceErr.lev
            let newData: number[][] = [];
            let i = levData.length - 1;
            while (i >= 0) {
              newData.push([levData[i][0] * 1000, levData[i][1]])
              i--
            }
            levData = newData
          }
          if ((props as LevAndPriceErrQueryResponse).accountLevAndPriceErr.priceErr) {
            // @ts-ignore
            priceErrData = (props as LevAndPriceErrQueryResponse).accountLevAndPriceErr.priceErr
            let newData: number[][] = [];
            let i = priceErrData.length - 1;
            while (i >= 0) {
              newData.push([priceErrData[i][0] * 1000, priceErrData[i][1]])
              i--
            }
            priceErrData = newData
          }
        }

        return (
          <>
            <Loading isLoading={!props && !error} />
            <div id="levAndPriceErrChartWrapper">
              <LevAndPriceErrChart
                error={error}
                levData={levData}
                priceErrData={priceErrData}
                loading={!props}
              />
            </div>
          </>
        )
      }}
    />
  )
}
