/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PositionsQueryVariables = {
    userID: string;
    skip: number;
    limit: number;
};
export type PositionsQueryResponse = {
    readonly positions: ReadonlyArray<{
        readonly accountID: string;
        readonly instrument: string;
        readonly size: number;
        readonly leverage: number;
        readonly entryPrice: number;
        readonly marketPrice: number;
        readonly priceError: number;
    }>;
};
export type PositionsQuery = {
    readonly response: PositionsQueryResponse;
    readonly variables: PositionsQueryVariables;
};



/*
query PositionsQuery(
  $userID: ID!
  $skip: Int!
  $limit: Int!
) {
  positions(userID: $userID, skip: $skip, limit: $limit) {
    accountID
    instrument
    size
    leverage
    entryPrice
    marketPrice
    priceError
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userID"
},
v3 = [
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "skip",
    "variableName": "skip"
  },
  {
    "kind": "Variable",
    "name": "userID",
    "variableName": "userID"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountID",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instrument",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "leverage",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entryPrice",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "marketPrice",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "priceError",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PositionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Position",
        "kind": "LinkedField",
        "name": "positions",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PositionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Position",
        "kind": "LinkedField",
        "name": "positions",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1d87b1cea899b12e1e6a257f26cccbf6",
    "id": null,
    "metadata": {},
    "name": "PositionsQuery",
    "operationKind": "query",
    "text": "query PositionsQuery(\n  $userID: ID!\n  $skip: Int!\n  $limit: Int!\n) {\n  positions(userID: $userID, skip: $skip, limit: $limit) {\n    accountID\n    instrument\n    size\n    leverage\n    entryPrice\n    marketPrice\n    priceError\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '80a95eac88c94002f5282e213c17c201';
export default node;
