import React, { PropsWithChildren, useContext } from 'react'
import { RelayEnvironmentProvider } from 'relay-hooks'

import { createEnvironment } from './environment'
import { UserContext } from '../user'

interface IProps { }

const RelayContext = ({ children }: PropsWithChildren<IProps>) => {
  const { clearUser } = useContext(UserContext)
  const environment = createEnvironment({clearUser})

  return (
    <RelayEnvironmentProvider environment={environment}>
      <>{children}</>
    </RelayEnvironmentProvider>
  )
}

export default RelayContext
