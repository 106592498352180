/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AccountRiskEventsQueryVariables = {
    accountID: string;
    skip: number;
    limit: number;
};
export type AccountRiskEventsQueryResponse = {
    readonly accountRiskEvents: ReadonlyArray<{
        readonly id: string;
        readonly accountID: string;
        readonly createdAt: string;
        readonly event: string;
    }>;
};
export type AccountRiskEventsQuery = {
    readonly response: AccountRiskEventsQueryResponse;
    readonly variables: AccountRiskEventsQueryVariables;
};



/*
query AccountRiskEventsQuery(
  $accountID: ID!
  $skip: Int!
  $limit: Int!
) {
  accountRiskEvents(accountID: $accountID, skip: $skip, limit: $limit) {
    id
    accountID
    createdAt
    event
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountID"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountID",
        "variableName": "accountID"
      },
      {
        "kind": "Variable",
        "name": "limit",
        "variableName": "limit"
      },
      {
        "kind": "Variable",
        "name": "skip",
        "variableName": "skip"
      }
    ],
    "concreteType": "RiskEvent",
    "kind": "LinkedField",
    "name": "accountRiskEvents",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accountID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "event",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountRiskEventsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AccountRiskEventsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "c578e4c7c545bc5b93a5e8c413976e62",
    "id": null,
    "metadata": {},
    "name": "AccountRiskEventsQuery",
    "operationKind": "query",
    "text": "query AccountRiskEventsQuery(\n  $accountID: ID!\n  $skip: Int!\n  $limit: Int!\n) {\n  accountRiskEvents(accountID: $accountID, skip: $skip, limit: $limit) {\n    id\n    accountID\n    createdAt\n    event\n  }\n}\n"
  }
};
})();
(node as any).hash = '9204c0dc0e9cf5590d1bfc35a9ced627';
export default node;
