/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AccountRiskParametersQueryVariables = {
    accountID: string;
};
export type AccountRiskParametersQueryResponse = {
    readonly accountRiskParams: {
        readonly id: string;
        readonly accountID: string;
        readonly weeklyPriceMovementCap: number;
        readonly maxPercentLoss: number;
        readonly leverageCloseoutWithLossThreshold: number;
        readonly leverageCloseoutWithLossPriceErr: number;
        readonly tradingHaltedUntil: string | null;
        readonly hoursToHaltTrading: number;
        readonly deleverageLeverageLimit: number;
        readonly deleverageLeverageLimitCloseoutRatio: number;
        readonly priceErr: number;
    } | null;
};
export type AccountRiskParametersQuery = {
    readonly response: AccountRiskParametersQueryResponse;
    readonly variables: AccountRiskParametersQueryVariables;
};



/*
query AccountRiskParametersQuery(
  $accountID: ID!
) {
  accountRiskParams(accountID: $accountID) {
    id
    accountID
    weeklyPriceMovementCap
    maxPercentLoss
    leverageCloseoutWithLossThreshold
    leverageCloseoutWithLossPriceErr
    tradingHaltedUntil
    hoursToHaltTrading
    deleverageLeverageLimit
    deleverageLeverageLimitCloseoutRatio
    priceErr
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountID"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountID",
        "variableName": "accountID"
      }
    ],
    "concreteType": "RiskParams",
    "kind": "LinkedField",
    "name": "accountRiskParams",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accountID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "weeklyPriceMovementCap",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maxPercentLoss",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "leverageCloseoutWithLossThreshold",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "leverageCloseoutWithLossPriceErr",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tradingHaltedUntil",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hoursToHaltTrading",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleverageLeverageLimit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleverageLeverageLimitCloseoutRatio",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "priceErr",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountRiskParametersQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountRiskParametersQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c9412eca9104c2d439a386a4a650f66e",
    "id": null,
    "metadata": {},
    "name": "AccountRiskParametersQuery",
    "operationKind": "query",
    "text": "query AccountRiskParametersQuery(\n  $accountID: ID!\n) {\n  accountRiskParams(accountID: $accountID) {\n    id\n    accountID\n    weeklyPriceMovementCap\n    maxPercentLoss\n    leverageCloseoutWithLossThreshold\n    leverageCloseoutWithLossPriceErr\n    tradingHaltedUntil\n    hoursToHaltTrading\n    deleverageLeverageLimit\n    deleverageLeverageLimitCloseoutRatio\n    priceErr\n  }\n}\n"
  }
};
})();
(node as any).hash = '6016ac3ddd8d8a14299395099f22bd5f';
export default node;
