/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AccountPositionsQueryVariables = {
    accountID: string;
};
export type AccountPositionsQueryResponse = {
    readonly accountPositions: ReadonlyArray<{
        readonly accountID: string;
        readonly instrument: string;
        readonly size: number;
        readonly leverage: number;
        readonly entryPrice: number;
        readonly marketPrice: number;
        readonly priceError: number;
    }>;
};
export type AccountPositionsQuery = {
    readonly response: AccountPositionsQueryResponse;
    readonly variables: AccountPositionsQueryVariables;
};



/*
query AccountPositionsQuery(
  $accountID: ID!
) {
  accountPositions(accountID: $accountID) {
    accountID
    instrument
    size
    leverage
    entryPrice
    marketPrice
    priceError
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountID",
    "variableName": "accountID"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountID",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instrument",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "leverage",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entryPrice",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "marketPrice",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "priceError",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountPositionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Position",
        "kind": "LinkedField",
        "name": "accountPositions",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountPositionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Position",
        "kind": "LinkedField",
        "name": "accountPositions",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "748dae7892fbf72df92872714f83861a",
    "id": null,
    "metadata": {},
    "name": "AccountPositionsQuery",
    "operationKind": "query",
    "text": "query AccountPositionsQuery(\n  $accountID: ID!\n) {\n  accountPositions(accountID: $accountID) {\n    accountID\n    instrument\n    size\n    leverage\n    entryPrice\n    marketPrice\n    priceError\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ff04020c16f865e356867845d075a67d';
export default node;
