import {
  Environment,
  GraphQLResponse,
  Network,
  RecordSource,
  RequestParameters,
  Store,
  Variables,
  //Observable,
  //Disposable,
} from 'relay-runtime'
//import { SubscriptionClient } from 'subscriptions-transport-ws'

import {
  API_URL,
  //WS_URL
} from '../../config'

/*
const subscriptionClient = new SubscriptionClient(`${WS_URL}/graphql`, {
  reconnect: true,
});

const subscribe = (request, variables): Observable<GraphQLResponse> | Disposable => {
  console.log(`subscribe request ${JSON.stringify(request)}, variables: ${JSON.stringify(variables)}`)
  const subscribeObservable = subscriptionClient.request({
    query: request.text,
    operationName: request.name,
    variables,
  });

  // Important: Convert subscriptions-transport-ws observable type to Relay's
  // @ts-ignore
  return Observable.from(subscribeObservable);
};
*/

type createFetchQueryProps = {
  clearUser: () => void
}

const createFetchQuery = ({
  clearUser,
}: createFetchQueryProps) => {
  const fetchQuery = async (request: RequestParameters, variables: Variables): Promise<GraphQLResponse> => {
    let headers = {
        'Content-Type': 'application/json',
    }
    let sessionToken = localStorage.getItem('session-token')
    if (sessionToken && sessionToken !== '') {
      headers['Authorization'] = `Bearer ${sessionToken}`
    }

    const res = await fetch(`${API_URL}/graphql`, {
      method: 'POST',
      mode: 'cors',
      headers,
      body: JSON.stringify({
        operationName: request.name,
        query: request.text,
        variables
      })
    })

    if (res.status === 401) {
      clearUser()
    }

    localStorage.setItem('session-token', res.headers.get("X-Session-Token"))
    localStorage.setItem('session-expires', res.headers.get("X-Session-Expires"))

    return res.json()
  }

  return fetchQuery
}

type createEnvironmentProps = {
  clearUser: () => void
}

export const createEnvironment = ({
  clearUser,
}: createEnvironmentProps) => {
  const fetchQuery = createFetchQuery({clearUser})

  return new Environment({
    //network: Network.create(fetchQuery, subscribe),
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource())
  })
}
