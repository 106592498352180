/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type OrdersQueryVariables = {
    accountID: string;
    skip: number;
    limit: number;
};
export type OrdersQueryResponse = {
    readonly accountOrders: ReadonlyArray<{
        readonly id: string;
        readonly createdAt: string;
        readonly marketID: string;
        readonly customID: string;
        readonly playID: string;
        readonly instrument: string;
        readonly side: string;
        readonly volume: number;
        readonly price: number;
    }>;
};
export type OrdersQuery = {
    readonly response: OrdersQueryResponse;
    readonly variables: OrdersQueryVariables;
};



/*
query OrdersQuery(
  $accountID: ID!
  $skip: Int!
  $limit: Int!
) {
  accountOrders(accountID: $accountID, skip: $skip, limit: $limit) {
    id
    createdAt
    marketID
    customID
    playID
    instrument
    side
    volume
    price
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountID"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountID",
        "variableName": "accountID"
      },
      {
        "kind": "Variable",
        "name": "limit",
        "variableName": "limit"
      },
      {
        "kind": "Variable",
        "name": "skip",
        "variableName": "skip"
      }
    ],
    "concreteType": "Order",
    "kind": "LinkedField",
    "name": "accountOrders",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "marketID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "playID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "instrument",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "side",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "volume",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrdersQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrdersQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "02326b179f5d5882d23d36f0bae8c164",
    "id": null,
    "metadata": {},
    "name": "OrdersQuery",
    "operationKind": "query",
    "text": "query OrdersQuery(\n  $accountID: ID!\n  $skip: Int!\n  $limit: Int!\n) {\n  accountOrders(accountID: $accountID, skip: $skip, limit: $limit) {\n    id\n    createdAt\n    marketID\n    customID\n    playID\n    instrument\n    side\n    volume\n    price\n  }\n}\n"
  }
};
})();
(node as any).hash = '3e1deb7d86d1548f685f228d98b03580';
export default node;
