import React from 'react'
import {
  Table,
} from 'reactstrap'
import {
  QueryRenderer
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useRelayEnvironment } from 'relay-hooks'

import { AccountTradingParametersQueryResponse } from './__generated__/AccountTradingParametersQuery.graphql'
import Loading from '../../../../components/Loading'

type AccountTradingParametersType = {
  readonly accountID: string;
  readonly forecastNumTradesPerSide: number;
  readonly percentCapitalToAllocate: number;
  readonly liqMgmtNumTradesPerSide: number;
  readonly liqMgmtNumStdDevsSpread: number;
  readonly forecastTotalNumStdDevSpread: number;
  readonly minPercentDistFromVWAP: number;
  readonly minPercentDistFromAVGEntry: number;
  readonly forecastCloseoutThresholdPercentage: number;
  readonly previousDayMultiplier: number;
  readonly stdDevMultiplier: number;
}

const AccountTradingParameter = (
  {
    index,
    accountTradingParameters: {
      accountID,
      forecastNumTradesPerSide,
      percentCapitalToAllocate,
      liqMgmtNumTradesPerSide,
      liqMgmtNumStdDevsSpread,
      forecastTotalNumStdDevSpread,
      minPercentDistFromVWAP,
      minPercentDistFromAVGEntry,
      previousDayMultiplier,
      stdDevMultiplier,
    }
  }:
    {
      index: number;
      accountTradingParameters: AccountTradingParametersType;
    }
) => {
  return (
    <tr key={index}>
      <td style={{ textAlign: 'center' }}>
        {accountID}
      </td>
      <td style={{ textAlign: 'center' }}>
        {forecastNumTradesPerSide}
      </td>
      <td style={{ textAlign: 'center' }}>
        {(percentCapitalToAllocate).toFixed(4)}%
      </td>
      <td style={{ textAlign: 'center' }}>
        {liqMgmtNumTradesPerSide}
      </td>
      <td style={{ textAlign: 'center' }}>
        {liqMgmtNumStdDevsSpread}
      </td>
      <td style={{ textAlign: 'center' }}>
        {forecastTotalNumStdDevSpread}
      </td>
      <td style={{ textAlign: 'center' }}>
        {(minPercentDistFromVWAP * 100).toFixed(4)}%
      </td>
      <td style={{ textAlign: 'center' }}>
        {(minPercentDistFromAVGEntry * 100).toFixed(4)}%
      </td>
      <td style={{ textAlign: 'center' }}>
        {previousDayMultiplier}
      </td>
      <td style={{ textAlign: 'center' }}>
        {stdDevMultiplier}
      </td>
    </tr>
  )
}

export interface AccountTradingParametersQueryProps {
  accountID: string;
}

const AccountTradingParametersQuery = ({ accountID }: AccountTradingParametersQueryProps) => {
  const environment = useRelayEnvironment()

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query AccountTradingParametersQuery($accountID: ID!) {
          accountConfigs(accountID: $accountID) {
            accountID,
            forecastNumTradesPerSide,
            percentCapitalToAllocate,
            liqMgmtNumTradesPerSide,
            liqMgmtNumStdDevsSpread,
            forecastTotalNumStdDevSpread,
            minPercentDistFromVWAP,
            minPercentDistFromAVGEntry,
            forecastCloseoutThresholdPercentage,
            previousDayMultiplier,
            stdDevMultiplier,
          }
        }
      `}
      variables={{
        accountID,
      }}
      render={({ error, props }) => {
        if (error) {
          console.error(error)
          return <>Error</>
        }

        let params: AccountTradingParametersQueryResponse["accountConfigs"] = []
        if (props) {
          params = (props as AccountTradingParametersQueryResponse).accountConfigs
        }

        return (
          <>
            {!props && !error ? <tr><td colSpan={7}><Loading isLoading={!props && !error} /></td></tr> : <></>}
            {params.map((p, idx) => (
              <AccountTradingParameter
                key={idx}
                index={idx}
                accountTradingParameters={p}
              />
            ))}
          </>
        )
      }}
    />
  )
}

export interface AccountTradingParametersProps {
  accountID: string;
}

export const AccountTradingParameters = ({ accountID }: AccountTradingParametersProps) => {
  return (
    <Table responsive className="center">
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>
            Account ID
          </th>
          <th style={{ textAlign: 'center' }}>
            Forecast Traders per Side
          </th>
          <th style={{ textAlign: 'center' }}>
            Percent Capital to Allocate
          </th>
          <th style={{ textAlign: 'center' }}>
            Liq. Mgmt. Trades Pers Side
          </th>
          <th style={{ textAlign: 'center' }}>
            Liq. Mgmt. Number Std. Dev. Spread
          </th>
          <th style={{ textAlign: 'center' }}>
            Forecast Number Std. Dev. Spread
          </th>
          <th style={{ textAlign: 'center' }}>
            Min Percent Distance from VWAP
          </th>
          <th style={{ textAlign: 'center' }}>
            Min Percent Distance from Avg. Entry
          </th>
          <th style={{ textAlign: 'center' }}>
            Balance Multiplier
          </th>
          <th style={{ textAlign: 'center' }}>
            Std. Dev. Multiplier
          </th>
        </tr>
      </thead>
      <tbody>
        <AccountTradingParametersQuery accountID={accountID} />
      </tbody>
    </Table>
  )
}
