import React from 'react'
import {
    Table,
} from 'reactstrap'
import {
    QueryRenderer
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useRelayEnvironment } from 'relay-hooks'

import { AccountPositionsQueryResponse } from './__generated__/AccountPositionsQuery.graphql'
import Loading from '../../../../components/Loading'

type PositionType = {
    readonly accountID: string;
    readonly instrument: string;
    readonly size: number;
    readonly leverage: number;
    readonly entryPrice: number;
    readonly marketPrice: number;
    readonly priceError: number;
}

const Position = (
    {
        index,
        position: {
            accountID,
            instrument,
            size,
            leverage,
            entryPrice,
            marketPrice,
            priceError,
        }
    }:
        {
            index: number
            position: PositionType
        }
) => {
    return (
        <tr key={index}>
            <td style={{ textAlign: 'center' }}>
                {accountID}
            </td>
            <td style={{ textAlign: 'center' }}>
                {instrument}
            </td>
            <td style={{ textAlign: 'right' }}>
                {size.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2 })} {instrument.split("-")[0]}
            </td>
            <td style={{ textAlign: 'right' }}>
                {leverage.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2 })}
            </td>
            <td style={{ textAlign: 'right' }}>
                {size === 0 ? "NA" : entryPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 4 })}
            </td>
            <td style={{ textAlign: 'right' }}>
                {marketPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 4 })}
            </td>
            <td style={{ textAlign: 'right' }}>
                {size === 0 ? "NA" : priceError.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 4 })}
            </td>
        </tr>
    )
}

export interface PositionsQueryProps {
    accountID: string;
}
const PositionsQuery = ({ accountID }: PositionsQueryProps) => {
    const environment = useRelayEnvironment()

    return (
        <QueryRenderer
            environment={environment}
            query={graphql`
        query AccountPositionsQuery($accountID: ID!) {
          accountPositions(accountID: $accountID) {
            accountID
            instrument
            size
            leverage
            entryPrice
            marketPrice
            priceError
          }
        }
      `}
            variables={{
                accountID,
            }}
            render={({ error, props }) => {
                if (error) {
                    console.error(error)
                    return <>Error</>
                }

                let positions: AccountPositionsQueryResponse["accountPositions"] = []
                if (props) {
                    positions = (props as AccountPositionsQueryResponse).accountPositions
                }

                return (
                    <>
                        {!props && !error ? <tr><td colSpan={7}><Loading isLoading={!props && !error} /></td></tr> : <></>}
                        {positions.map((p, idx) => (
                            <Position
                                key={idx}
                                index={idx}
                                position={p}
                            />
                        ))}
                    </>
                )
            }}
        />
    )
}

export interface PositionsProps {
    accountID: string;
}

export const Positions = ({ accountID }: PositionsProps) => {
    return (
        <Table responsive className="center">
            <thead>
                <tr>
                    <th style={{ textAlign: 'center' }}>
                        Account ID
                    </th>
                    <th style={{ textAlign: 'center' }}>
                        Instrument
                    </th>
                    <th style={{ textAlign: 'right' }}>
                        Size
                    </th>
                    <th style={{ textAlign: 'right' }}>
                        Leverage
                    </th>
                    <th style={{ textAlign: 'right' }}>
                        Entry Price
                    </th>
                    <th style={{ textAlign: 'right' }}>
                        Market Price
                    </th>
                    <th style={{ textAlign: 'right' }}>
                        Price Error
                    </th>
                </tr>
            </thead>
            <tbody>
                <PositionsQuery accountID={accountID} />
            </tbody>
        </Table>
    )
}
