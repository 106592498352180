import React from 'react'
import {
    Table,
} from 'reactstrap'
import {
    QueryRenderer
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useRelayEnvironment } from 'relay-hooks'

import { AccountSummaryQueryResponse } from './__generated__/AccountSummaryQuery.graphql'
import Loading from '../../../../components/Loading'

export interface SummaryQueryProps {
    accountID: string;
}

const SummaryQuery = ({ accountID }: SummaryQueryProps) => {
    const environment = useRelayEnvironment()

    return (
        <QueryRenderer
            environment={environment}
            query={graphql`
        query AccountSummaryQuery($accountID: ID!) {
          accountSummary(accountID: $accountID) {
            totalReturn
            cagr
            sharpe
            maxDD
            betaGSPC
            betaUnderlying
          }
        }
      `}
            variables={{
                accountID
            }}
            render={({ error, props }) => {
                if (error) {
                    console.error(error)
                    return <>Error</>
                }

                let totalReturn = -1
                let cagr = -1
                let sharpe = -1
                let maxDD = -1

                if (props) {
                    totalReturn = (props as AccountSummaryQueryResponse).accountSummary.totalReturn
                    cagr = (props as AccountSummaryQueryResponse).accountSummary.cagr
                    sharpe = (props as AccountSummaryQueryResponse).accountSummary.sharpe
                    maxDD = (props as AccountSummaryQueryResponse).accountSummary.maxDD
                }

                return (
                    <>
                        {!props && !error ? <tr><td colSpan={6}><Loading isLoading={!props && !error} /></td></tr> : <></>}
                        <tr>
                            <td>
                                {(totalReturn * 100).toFixed(4)}%
                            </td>
                            <td>
                                {(cagr * 100).toFixed(4)}%
                            </td>
                            <td>
                                {(maxDD * 100).toFixed(4)}%
                            </td>
                            <td>
                                {sharpe.toFixed(4)}
                            </td>
                            <td>
                                Not Yet Implemented
                            </td>
                            <td>
                                Not Yet Implemented
                            </td>
                        </tr>
                    </>
                )
            }}
        />
    )
}

export interface SummaryProps {
    accountID: string;
}

export const Summary = ({ accountID }: SummaryProps) => {
    return (
        <Table responsive className="center" style={{ textAlign: 'center' }}>
            <thead>
                <tr>
                    <th>
                        Total Return
                    </th>
                    <th>
                        Compound Annual Growth Rate
                    </th>
                    <th>
                        Max Draw Down
                    </th>
                    <th>
                        Sharpe (Rf = 3%)
                    </th>
                    <th>
                        Beta (^GSPC)
                    </th>
                    <th>
                        Beta (underlying)
                    </th>
                </tr>
            </thead>
            <tbody>
                <SummaryQuery accountID={accountID} />
            </tbody>
        </Table>
    )
}
