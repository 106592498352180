import React from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCardFooter,
} from '@coreui/react'

import { Chart } from './CumulativeReturns/Chart'
import { Summary } from './Summary'

export interface PerformanceProps {
  accountID: string;
}

const Performance = ({ accountID }: PerformanceProps) => {
  return (
    <>
      <CCard style={{ position: "relative" }}>
        <CCardBody>
          <Chart accountID={accountID} />
        </CCardBody>
      </CCard>
      <CCard style={{ position: "relative" }}>
        <CCardHeader>
          <h1>Summary</h1>
        </CCardHeader>
        <CCardBody>
          <Summary accountID={accountID} />
        </CCardBody>
        <CCardFooter>
          Updated daily at midnight UTC.
        </CCardFooter>
      </CCard>
    </>
  )
}

export default Performance
