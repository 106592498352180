import React, { createContext, useState, useEffect } from "react"

export interface UserFields {
  id: string;
  email: string;
  isAdmin: boolean;
}

export interface User extends UserFields {
  clearUser(): void
  setUser(arg0: UserFields): void
}

export const UserContext = createContext<User | undefined>(undefined);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState<UserFields | undefined>(undefined)
  const clearUser = () => {
    setUser(undefined)
  }

  if (user && user.id && user.id !== '') {
    localStorage.setItem('user', JSON.stringify(user))
  }

  useEffect(() => {
    const tmpUserStr = localStorage.getItem('user')
    if (tmpUserStr) {
      const tmpUser = JSON.parse(tmpUserStr)
      setUser(tmpUser)
    }
  }, [])

  return (
    <UserContext.Provider
      value={{
        ...user,
        clearUser,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
