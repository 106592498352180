/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type RiskQueryVariables = {
    userID: string;
    confidenceLevels: Array<number>;
};
export type RiskQueryResponse = {
    readonly userValueAtRisk: ReadonlyArray<{
        readonly accountID: string;
        readonly confidenceLevel: number;
        readonly risk: number;
    }>;
};
export type RiskQuery = {
    readonly response: RiskQueryResponse;
    readonly variables: RiskQueryVariables;
};



/*
query RiskQuery(
  $userID: ID!
  $confidenceLevels: [Float!]!
) {
  userValueAtRisk(userID: $userID, confidenceLevels: $confidenceLevels) {
    accountID
    confidenceLevel
    risk
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "confidenceLevels"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userID"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "confidenceLevels",
        "variableName": "confidenceLevels"
      },
      {
        "kind": "Variable",
        "name": "userID",
        "variableName": "userID"
      }
    ],
    "concreteType": "ValueAtRisk",
    "kind": "LinkedField",
    "name": "userValueAtRisk",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accountID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "confidenceLevel",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "risk",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RiskQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RiskQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4541dffcc38101a01ce6915ac6ecb198",
    "id": null,
    "metadata": {},
    "name": "RiskQuery",
    "operationKind": "query",
    "text": "query RiskQuery(\n  $userID: ID!\n  $confidenceLevels: [Float!]!\n) {\n  userValueAtRisk(userID: $userID, confidenceLevels: $confidenceLevels) {\n    accountID\n    confidenceLevel\n    risk\n  }\n}\n"
  }
};
})();
(node as any).hash = '4103339e9d744c65a920d2c0dab41eed';
export default node;
