import React, { useState, useContext  } from 'react'
import { Redirect } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
  CModalFooter,
  CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { UserContext } from '../../../contexts/user'
import { API_URL } from '../../../config'

const Login = () => {
  const userCTX = useContext(UserContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [modal, setModal] = useState(false)

  const login = async (data: { email: string; password: string; }) => {
    try {
      const res = await fetch(`${API_URL}/login`, {
        method: 'POST',
         mode: 'cors',
        //mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })

      localStorage.setItem('session-token', res.headers.get("X-Session-Token"))
      localStorage.setItem('session-expires', res.headers.get("X-Session-Expires"))

      const user = await res.json()
      userCTX.setUser(user)
    } catch(e) {
      console.error('err logging in', e)
      setModal(true)
    }
  }

  if (userCTX && userCTX.id && userCTX.id !== '') {
    return <Redirect to="/dashboard" />
  }

  return (
    <>
      <CModal show={modal} onClose={setModal}>
        <CModalHeader closeButton>
          <CModalTitle>Login Error</CModalTitle>
        </CModalHeader>
        <CModalBody>Incorrect username or password.</CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setModal(false)}>
            Close
          </CButton>
        </CModalFooter>
      </CModal>
      <div className="c-app c-default-layout flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="8">
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <CInputGroup className="mb-3">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-user" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          type="text"
                          placeholder="E-mail"
                          autoComplete="email"
                          value={email}
                          onChange={e => {
                            setEmail((e.target as HTMLInputElement).value)
                          }}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CInputGroupPrepend>
                          <CInputGroupText>
                            <CIcon name="cil-lock-locked" />
                          </CInputGroupText>
                        </CInputGroupPrepend>
                        <CInput
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          value={password}
                          onChange={e => {
                            setPassword((e.target as HTMLInputElement).value)
                          }}
                        />
                      </CInputGroup>
                      <CRow>
                        <CCol xs="6">
                          <CButton color="primary" className="px-4" onClick={() => { void login({ email, password })} }>Login</CButton>
                        </CCol>
                        {/*
                        <CCol xs="6" className="text-right">
                          <CButton color="link" className="px-0">Forgot password?</CButton>
                        </CCol>
                        */}
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  )
}

export default Login
