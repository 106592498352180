import React from 'react'
import {
  Table,
} from 'reactstrap'
import {
  QueryRenderer
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useRelayEnvironment } from 'relay-hooks'

import { AccountRiskEventsQueryResponse } from './__generated__/AccountRiskEventsQuery.graphql'
import Loading from '../../../../components/Loading'

type AccountRiskEventType = {
  readonly id: string;
  readonly accountID: string;
  readonly createdAt: string;
  readonly event: string;
}

const AccountRiskEvent = (
  {
    index,
    accountRiskEvent: {
      id,
      accountID,
      createdAt,
      event,
    }
  }:
    {
      index: number
      accountRiskEvent: AccountRiskEventType
    }
) => {
  return (
    <tr key={index}>
      <td style={{ textAlign: 'center' }}>
        {id}
      </td>
      <td style={{ textAlign: 'center' }}>
        {accountID}
      </td>
      <td style={{ textAlign: 'center' }}>
        {createdAt}
      </td>
      <td style={{ textAlign: 'center' }}>
        {event}
      </td>
    </tr>
  )
}

export interface AccountRiskEventsQueryProps {
  accountID: string;
  limit: number;
  skip: number;
}
const AccountRiskEventsQuery = ({ accountID, limit, skip }: AccountRiskEventsQueryProps) => {
  const environment = useRelayEnvironment()

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query AccountRiskEventsQuery($accountID: ID!, $skip: Int!, $limit: Int!) {
          accountRiskEvents(accountID: $accountID, skip: $skip, limit: $limit) {
            id
            accountID
            createdAt
            event
          }
        }
      `}
      variables={{
        accountID,
        limit,
        skip,
      }}
      render={({ error, props }) => {
        if (error) {
          console.error(error)
          return <>Error</>
        }

        let events: AccountRiskEventsQueryResponse["accountRiskEvents"] = []
        if (props) {
          events = (props as AccountRiskEventsQueryResponse).accountRiskEvents
        }

        return (
          <>
            {!props && !error ? <tr><td colSpan={4}><Loading isLoading={!props && !error} /></td></tr> : <></>}
            {events ? events.map((r, idx) => (
              r ? <AccountRiskEvent
                key={idx}
                index={idx}
                accountRiskEvent={r}
              />
                :
                <></>
            ))
              :
              <></>}
          </>
        )
      }}
    />
  )
}

export interface AccountRiskEventsProps {
  accountID: string;
  limit: number;
  skip: number;
}

export const AccountRiskEvents = ({ accountID, limit, skip }: AccountRiskEventsProps) => {
  return (
    <Table responsive className="center">
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>
            ID
          </th>
          <th style={{ textAlign: 'center' }}>
            Account ID
          </th>
          <th style={{ textAlign: 'center' }}>
            Created At
          </th>
          <th style={{ textAlign: 'center' }}>
            Event
          </th>
        </tr>
      </thead>
      <tbody>
        <AccountRiskEventsQuery
          accountID={accountID}
          limit={limit}
          skip={skip}
        />
      </tbody>
    </Table>
  )
}
