import React from 'react'
import {
  Table,
} from 'reactstrap'
import {
  QueryRenderer
} from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useRelayEnvironment } from 'relay-hooks'

import { AccountForecastParametersQueryResponse } from './__generated__/AccountForecastParametersQuery.graphql'
import Loading from '../../../../components/Loading'

type AccountForecastParametersType = {
  readonly id: string;
  readonly C: number;
  readonly A0: number;
  readonly A1: number;
  readonly B0: number;
  readonly B1: number;
}

const AccountForecastParameter = (
  {
    accountForecastParameters: {
      id,
      C,
      A0,
      A1,
      B0,
      B1,
    }
  }:
    {
      accountForecastParameters: AccountForecastParametersType;
    }
) => {
  return (
    <tr>
      <td style={{ textAlign: 'center' }}>
        {id}
      </td>
      <td style={{ textAlign: 'center' }}>
        {C.toExponential(4)}
      </td>
      <td style={{ textAlign: 'center' }}>
        {A0.toExponential(4)}
      </td>
      <td style={{ textAlign: 'center' }}>
        {A1.toExponential(4)}
      </td>
      <td style={{ textAlign: 'center' }}>
        {B0.toExponential(4)}
      </td>
      <td style={{ textAlign: 'center' }}>
        {B1.toExponential(4)}
      </td>
    </tr>
  )
}

export interface AccountForecastParametersQueryProps {
  accountID: string;
}

const AccountForecastParametersQuery = ({ accountID }: AccountForecastParametersQueryProps) => {
  const environment = useRelayEnvironment()

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query AccountForecastParametersQuery($accountID: ID!) {
          accountForecastParams(accountID: $accountID) {
            id
            C
            A0
            A1
            B0
            B1
          }
        }
      `}
      variables={{
        accountID,
      }}
      render={({ error, props }) => {
        if (error) {
          console.error(error)
          return <>Error</>
        }

        let params: AccountForecastParametersQueryResponse["accountForecastParams"]
        if (props) {
          params = (props as AccountForecastParametersQueryResponse).accountForecastParams
        }

        return (
          <>
            {!props && !error ? <tr><td colSpan={7}><Loading isLoading={!props && !error} /></td></tr> : <></>}
            {params ? <AccountForecastParameter
              accountForecastParameters={params}
            />
              :
              <></>}
          </>
        )
      }}
    />
  )
}

export interface AccountForecastParametersProps {
  accountID: string;
}

export const AccountForecastParameters = ({ accountID }: AccountForecastParametersProps) => {
  return (
    <Table responsive className="center">
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>
            ID
          </th>
          <th style={{ textAlign: 'center' }}>
            C
          </th>
          <th style={{ textAlign: 'center' }}>
            A0
          </th>
          <th style={{ textAlign: 'center' }}>
            A1
          </th>
          <th style={{ textAlign: 'center' }}>
            B0
          </th>
          <th style={{ textAlign: 'center' }}>
            B1
          </th>
        </tr>
      </thead>
      <tbody>
        <AccountForecastParametersQuery accountID={accountID} />
      </tbody>
    </Table>
  )
}
