import React from 'react'
import CIcon from '@coreui/icons-react'

export default [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Accounts',
    icon: <CIcon name="cil-money" customClasses="c-sidebar-nav-icon" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'ETH-USD',
        to: '/accounts/f0d31024-18af-4aea-8cbd-58c073102878',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'BTC-USD',
        to: '/accounts/0af91fba-8ddb-4600-8cb6-6c34968bbfc7',
      },
    ]
  }
]
